import {
  Backdrop,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import {
  Auth,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import { useState } from "react";
import { loginErrorHandler } from "../util/loginErrorHandler";
import { validatePassword } from "../../../lib/auth-api/validation";
import { userCredentialToCookie } from "../../../lib/auth-api/userCredentialToCookie";

export const ChangePassword = ({
  auth,
  setMessage,
}: {
  auth: Auth;
  setMessage: (prop: string) => void;
}) => {
  const [passwordInputValue, setPasswordInputValue] = useState("");
  const [newPasswordInputValue, setNewPasswordInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const clearMessage = () => {
    setMessage("");
  };
  const handlePasswordInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessage();
    const newName = e.target.value;
    setPasswordInputValue(newName);
  };
  const handleNewPasswordInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMessage();
    const newName = e.target.value;
    setNewPasswordInputValue(newName);
  };

  const handlePasswordChangeButton = async () => {
    setIsButtonDisabled(true);
    const mail = auth.currentUser?.email ?? "";
    try {
      //まず現在のメールアドレスとパスワードでログイン
      await signInWithEmailAndPassword(auth, mail, passwordInputValue);
      if (!auth.currentUser) {
        setMessage("ますメールアドレスでログインしてください");
        setIsButtonDisabled(false);
        return;
      }
      const passwordValidateResult = validatePassword(newPasswordInputValue);
      if (!passwordValidateResult.result) {
        setMessage("無効なメールアドレスです");
        setIsButtonDisabled(false);
        return;
      }
      await updatePassword(auth.currentUser, newPasswordInputValue);
      setMessage("変更に成功しました");
    } catch (e) {
      loginErrorHandler(e, setMessage);
    }
    setIsButtonDisabled(false);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      />
      <p>パスワード変更</p>

      <Box component={"form"}>
        <TextField
          margin="normal"
          fullWidth
          variant="outlined"
          label="password"
          type="password"
          value={passwordInputValue}
          onChange={handlePasswordInputValue}
        />
        <TextField
          margin="normal"
          fullWidth
          variant="outlined"
          label="new password"
          type="password"
          value={newPasswordInputValue}
          onChange={handleNewPasswordInputValue}
        />

        <Button
          fullWidth
          variant="contained"
          onClick={handlePasswordChangeButton}
          sx={{ mt: 3, mb: 2 }}
        >
          パスワード変更
        </Button>
      </Box>
    </>
  );
};
