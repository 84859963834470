import { httpsCallable } from "firebase/functions";
import { functions } from "..";

export const sendPasswordResetMail = (email: string) => {
  const sendPasswordResetMail = httpsCallable(
    functions,
    "sendPasswordResetMail"
  );
  return sendPasswordResetMail({ email });
};
