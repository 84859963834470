import { Auth, User } from "@firebase/auth";
import {
  AppBar,
  Backdrop,
  Card,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import "firebase/functions";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../css/App.css";
import { AccountManager } from "./AccountManager";
import { MailArea } from "./login/MailArea";
import { ProviderArea } from "./login/ProviderArea";
import { ProviderId } from "./login/type";
import { NowLoading } from "./NowLoading";
import { PageTitle } from "./typography/PageTitle";
import { Header } from "./common/Header";
require("firebase/auth");

const providerIdArray: Array<ProviderId> = ["twitter.com", "google.com"];

export const Signup = ({
  auth,
  user,
  loading,
}: {
  auth: Auth;
  user: User | null | undefined;
  loading: boolean;
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { callback } = useParams();
  const navigate = useNavigate();

  if (loading || user === undefined) {
    return <NowLoading />;
  }

  if (user) {
    return (
      <AccountManager
        currentUser={user}
        auth={auth}
        callbackKey={callback}
        message={message}
        setMessage={setMessage}
      />
    );
  }

  return (
    <>
      <Header />

      <Container sx={{ maxWidth: "md", my: 5 }}>
        <PageTitle>新規登録</PageTitle>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              メールアドレス、Twitter認証、Google認証が利用できます。
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              <span style={{ fontWeight: "bold" }}>
                旧パズルスクエアの利用ユーザーも引き継ぎ前に新規登録が必要です。
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              既にアカウントをお持ちの場合：
              <span
                onClick={() => {
                  navigate("/login/" + callback);
                }}
                style={{ color: "#f50057", cursor: "pointer" }}
              >
                ログインページへ
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ my: 2 }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isButtonDisabled}
          />
          <Typography variant="h5" component="h3">
            {message}
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Card>
                <MailArea
                  auth={auth}
                  setMessage={setMessage}
                  currentUser={user}
                  signup
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <ProviderArea auth={auth} signup />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
