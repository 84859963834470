import React, { useState } from "react";

import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { Backdrop } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Auth } from "firebase/auth";
import { signupWithMail } from "../../../api/loginApi";
import { loginErrorHandler } from "../util/loginErrorHandler";

export type MailSignupProp = {
  auth: Auth;
  setMessage: (prop: string) => void;
};

export const MailSignup: React.FC<MailSignupProp> = (prop) => {
  const [mailAddressInputValue, setMailAddressInputValue] = React.useState("");
  const [passwordInputValue, setPasswordInputValue] = useState("");
  const [password2InputValue, setPassword2InputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { auth, setMessage } = prop;
  const clearMessage = () => {
    setMessage("");
  };
  const handleSignupButton = async () => {
    if (passwordInputValue !== password2InputValue) {
      setMessage("確認用パスワードを正しく入力してください");
    }
    setIsButtonDisabled(true);
    const result = await signupWithMail(
      auth,
      mailAddressInputValue,
      passwordInputValue
    ).catch((e) => loginErrorHandler(e, setMessage));
    console.log(auth.currentUser);
    setMessage(result?.message ?? "");
    setIsButtonDisabled(false);
  };
  const handleMailAddressInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMessage();
    const val = e.target.value;
    setMailAddressInputValue(val);
  };
  const handlePasswordInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessage();
    const val = e.target.value;
    setPasswordInputValue(val);
  };
  const handlePassword2InputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMessage();
    const val = e.target.value;
    setPassword2InputValue(val);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      />
      <Typography component="h1" variant="h5">
        メールアドレスで新規登録
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={mailAddressInputValue}
          onChange={handleMailAddressInputValue}
        />
        <TextField
          margin="normal"
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={passwordInputValue}
          onChange={handlePasswordInputValue}
        />
        <TextField
          margin="normal"
          fullWidth
          name="password2"
          label="Password (確認)"
          type="password"
          id="password2"
          autoComplete="current-password"
          value={password2InputValue}
          onChange={handlePassword2InputValue}
        />

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            handleSignupButton();
          }}
        >
          新規登録
        </Button>
      </Box>
    </>
  );
};
