import { Auth } from "@firebase/auth";
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  linkWithCredential
} from "firebase/auth";
import "firebase/functions";
import { commonAuthProcess } from "./commonAuthProcess";
import {
  loginErrorNewAccount
} from "./commonResultMessage";
require("firebase/auth");

export const signupWithMailMultiAccount = async (
  auth: Auth,
  mail: string,
  password: string
) => {
  const loginErrorResult = loginErrorNewAccount;
  const authProcess = async () => {
    if (auth.currentUser) {
      const credential = EmailAuthProvider.credential(mail, password);
      return linkWithCredential(auth.currentUser, credential);
    } else {
      return createUserWithEmailAndPassword(auth, mail, password);
    }
  };
  return commonAuthProcess(mail, password, authProcess, loginErrorResult);
};
