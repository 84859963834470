import { CommonResultMessage } from "./commonResultMessage";

export const validatePasswordAndMail = (
  password: string,
  mail: string
): CommonResultMessage => {
  let ret = validatePassword(password);
  if (!ret.result) {
    return ret;
  }
  ret = validateMail(mail);
  return ret;
};

export const validateMail = (mail: string): CommonResultMessage => {
  const mailRegix =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]*\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (mail.match(mailRegix) === null) {
    return { result: false, message: "無効なメールアドレスです" };
  }
  return { result: true };
};

export const validatePassword = (password: string): CommonResultMessage => {
  if (password.length < 6) {
    return { result: false, message: "パスワードは6文字以上にしてください" };
  }
  return { result: true };
};
