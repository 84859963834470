import React, { useState } from "react";

import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { Backdrop, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Auth } from "firebase/auth";
import { loginWithMail } from "../../../api/loginApi";
import { loginErrorHandler } from "../util/loginErrorHandler";
import { useNavigate } from "react-router-dom";

export type MailLoginProp = {
  auth: Auth;
  setMessage: (prop: string) => void;
  callbackKey?: string;
};

export const MailLogin: React.FC<MailLoginProp> = (props) => {
  const [mailAddressInputValue, setMailAddressInputValue] = React.useState("");
  const [passwordInputValue, setPasswordInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { auth, setMessage, callbackKey } = props;
  const navigate = useNavigate();
  const clearMessage = () => {
    setMessage("");
  };
  const handleLoginButton = async () => {
    setIsButtonDisabled(true);
    const result = await loginWithMail(
      auth,
      mailAddressInputValue,
      passwordInputValue
    ).catch((e) => loginErrorHandler(e, setMessage));
    console.log(auth.currentUser);
    setMessage(result?.message ?? "");
    setIsButtonDisabled(false);
  };
  const handleMailAddressInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMessage();
    const val = e.target.value;
    setMailAddressInputValue(val);
  };
  const handlePasswordInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessage();
    const val = e.target.value;
    setPasswordInputValue(val);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      />
      <Typography component="h1" variant="h5">
        メールアドレスでログイン
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={mailAddressInputValue}
          onChange={handleMailAddressInputValue}
        />
        <TextField
          margin="normal"
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={passwordInputValue}
          onChange={handlePasswordInputValue}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            handleLoginButton();
          }}
        >
          ログイン
        </Button>
        <Box sx={{ my: 2 }}>
          <Link
            onClick={() => {
              if (callbackKey) {
                navigate("/password-reset/" + callbackKey);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            パスワードを再設定する
          </Link>
        </Box>
      </Box>
    </>
  );
};
