import axios from "axios";

export const disableCookie = async (): Promise<any> => {
  return axios
    .post("/function/signout", undefined, {
      withCredentials: true,
    })
    .then((response) => {
      console.log(response.data);
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};
