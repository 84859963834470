import axios from "axios";

export const makeSessionCookie = async (idToken: string) => {
  return axios
    .post("/function/makeSessionCookie", {
      idToken: idToken,
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};
