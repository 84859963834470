import {
  Avatar,
  Box,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  Link,
} from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { Auth, User } from "firebase/auth";
import { ChangeMailaddress } from "./mail/ChangeMailaddress";
import { ChangePassword } from "./mail/ChangePassword";
import { MailLogin } from "./mail/MailLogin";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { MailSignup } from "./mail/MailSignup";
import { useNavigate } from "react-router-dom";

type MailProp = {
  currentUser: User | null;
  auth: Auth;
  setMessage: (prop: string) => void;
  signup?: boolean;
  callbackKey?: string;
};

const theme = createTheme();

export const MailArea = (prop: MailProp) => {
  const { currentUser, auth, setMessage } = prop;
  const navigate = useNavigate();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginY: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <EmailOutlinedIcon />
        </Avatar>
        {prop.signup ? (
          <MailSignup auth={auth} setMessage={setMessage} />
        ) : (
          <MailLogin
            auth={auth}
            setMessage={setMessage}
            callbackKey={prop.callbackKey}
          />
        )}
      </Box>
    </Container>
  );
};
