import { Auth, User } from "@firebase/auth";
import {
  AuthProvider,
  getRedirectResult,
  linkWithRedirect,
  signInWithRedirect,
  unlink,
} from "firebase/auth";
import "firebase/functions";
import { loginWithMailMultiAccount } from "../lib/auth-api/loginApiMultiAccount";
import { loginWithMailSingleAccount } from "../lib/auth-api/loginApiSingleAccount";
import { signupWithMailMultiAccount } from "../lib/auth-api/signupApiMultiAccount";
import { signupWithMailSingleAccount } from "../lib/auth-api/signupApiSingleAccount";
import { userCredentialToCookie } from "../lib/auth-api/userCredentialToCookie";
import { acceptMultiAccount } from "./parameter";
require("firebase/auth");

export const loginWithMail = async (
  auth: Auth,
  mail: string,
  password: string
) => {
  if (acceptMultiAccount) {
    return loginWithMailMultiAccount(auth, mail, password);
  } else {
    return loginWithMailSingleAccount(auth, mail, password);
  }
};

export const signupWithMail = async (
  auth: Auth,
  mail: string,
  password: string
) => {
  if (acceptMultiAccount) {
    return signupWithMailMultiAccount(auth, mail, password);
  } else {
    return signupWithMailSingleAccount(auth, mail, password);
  }
};

export const loginWithRedirect = async (auth: Auth, provider: AuthProvider) => {
  if (auth.currentUser && acceptMultiAccount) {
    await linkWithRedirect(auth.currentUser, provider);
  } else {
    await signInWithRedirect(auth, provider);
  }
};

export const unlinkAccount = async (currentUser: User, providerId: string) => {
  await unlink(currentUser, providerId);
};

export const checkRedirectResult = async (auth: Auth) => {
  return await getRedirectResult(auth)
    .then(async (userCredential) => {
      if (userCredential) {
        return await userCredentialToCookie(userCredential);
      } else {
        return { result: false };
      }
    })
    .catch((e) => {
      return e;
    });
};
