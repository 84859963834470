import { Auth, User } from "@firebase/auth";
import {
  AppBar,
  Backdrop,
  Card,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import "firebase/functions";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountManager } from "./AccountManager";
import { MailArea } from "./login/MailArea";
import { ProviderArea } from "./login/ProviderArea";
import { ProviderId } from "./login/type";
import { NowLoading } from "./NowLoading";
import { PageTitle } from "./typography/PageTitle";
import { Header } from "./common/Header";
require("firebase/auth");

const providerIdArray: Array<ProviderId> = ["twitter.com", "google.com"];

export const Login = ({
  auth,
  user,
  loading,
}: {
  auth: Auth;
  user: User | null | undefined;
  loading: boolean;
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { callback } = useParams();

  if (loading || user === undefined) {
    return <NowLoading />;
  }

  if (user) {
    return (
      <AccountManager
        currentUser={user}
        auth={auth}
        callbackKey={callback}
        message={message}
        setMessage={setMessage}
      />
    );
  }

  return (
    <>
      <Header />
      <Container sx={{ maxWidth: "md", my: 5 }}>
        <PageTitle>ログイン</PageTitle>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isButtonDisabled}
        />
        <Typography variant="h5" component="h3">
          {message}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              アカウントをお持ちでない場合：
              <span
                onClick={() => {
                  navigate("/signup/" + callback);
                }}
                style={{ color: "#f50057", cursor: "pointer" }}
              >
                新規登録ページへ
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              <span style={{ fontWeight: "bold" }}>
                旧パズルスクエアの利用ユーザーは、新規登録後にアカウントを引き継げます。
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ my: 2 }}>
          <Grid item xs={12} sm={6}>
            <Card>
              <MailArea
                auth={auth}
                setMessage={setMessage}
                currentUser={user}
                callbackKey={callback}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <ProviderArea auth={auth} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
