import { Auth } from "@firebase/auth";
import {
  EmailAuthProvider,
  linkWithCredential,
  signInWithEmailAndPassword
} from "firebase/auth";
import "firebase/functions";
import { commonAuthProcess } from "./commonAuthProcess";
import { loginErrorExistAccount } from "./commonResultMessage";
require("firebase/auth");

export const loginWithMailMultiAccount = async (
  auth: Auth,
  mail: string,
  password: string,
) => {
  const loginErrorResult = loginErrorExistAccount;
  const authProcess = async () => {
    if (auth.currentUser) {
      const credential = EmailAuthProvider.credential(mail, password);
      return linkWithCredential(auth.currentUser, credential);
    } else {
      return signInWithEmailAndPassword(auth, mail, password);
    }
  };
  return commonAuthProcess(mail, password, authProcess, loginErrorResult);
};
