import { Button, Typography } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { callbackArray } from "./data/callbackArray";
export const StartUsingButton = ({
  callback,
}: {
  callback: string | undefined;
}) => {
  const navigate = useNavigate();
  const callbackData = useMemo(() => {
    return callbackArray.find((e) => e.callback === callback);
  }, [callback]);
  const backToPreviousPage = () => {
    let nohit = true;
    callbackArray.forEach((val) => {
      if (val.callback === callback) {
        nohit = false;
        window.location.href = val.url;
      }
    });
    if (nohit) {
      navigate("/");
    }
  };
  return (
    (callbackData ?? null) && (
      <Button fullWidth variant="contained" onClick={backToPreviousPage}>
        <Typography variant="h6" component="h6">
          {callbackData?.buttonName ?? "前の画面に戻る"}
        </Typography>
      </Button>
    )
  );
};
