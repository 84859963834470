export const callbackArray = [
  {
    callback: "ig",
    url: "https://ig.logicpuzzle.app/config/user/linkage",
  },
  {
    callback: "ig-dev",
    url: "https://ig-dev.logicpuzzle.app/config/user/linkage",
  },
  {
    callback: "abyss",
    url: "https://abyss.logicpuzzle.app/login",
  },
  {
    callback: "abyss-dev",
    url: "http://abyss-dev-bnxhh.logicpuzzle.app/login",
  },
  {
    callback: "puzzle-square",
    url: "https://puzsq.logicpuzzle.app/login",
    buttonName: "パズルスクエアに移動",
  },
  {
    callback: "puzzle-square-dev-vtplm",
    url: "https://puzsq-dev-lhbvc.logicpuzzle.app/login",
    buttonName: "パズルスクエアに移動",
  },
  {
    callback: "pentool",
    url: "https://pentool.logicpuzzle.app/login",
    buttonName: "オンラインペンツールに移動",
  },
  {
    callback: "creator-hub",
    url: "https://creator-hub.logicpuzzle.app/",
    buttonName: "パズスククリエイターハブに移動",
  },
  {
    callback: "puzzle-creation-24h",
    url: "https://24h-creation.logicpuzzle.app/",
    buttonName: "パズル修羅に移動",
  },
  {
    callback: "parallel-world-puzzles-second-dev-opaf",
    url: "https://parallel-world-puzzles-second-dev-opaf.logicpuzzle.app",
    buttonName: "並行パズル邂逅記に移動",
  },
  {
    callback: "parallel-puzzle-ii",
    url: "https://parallel-puzzle-ii.logicpuzzle.app",
    buttonName: "並行パズル邂逅記に移動",
  },
];
