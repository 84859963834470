export const loginErrorHandler = (
  e: any,
  setMessage: (prop: string) => void
) => {
  console.log(e);
  const errorCodesRegix = [
    {
      regix: "auth/email-already-in-use",
      message: "認証失敗：そのメールアドレスは既に使われています",
    },
    {
      regix: "auth/provider-already-linked",
      message: "認証失敗：既に登録済みのアカウントと提携しようとしています",
    },
    {
      regix: "auth/wrong-password",
      message: "認証失敗：メールアドレスかパスワードが違います",
    },
    {
      regix: "auth/user-not-found",
      message: "認証失敗：メールアドレスかパスワードが違います",
    },
  ].map((v) => {
    return { regix: new RegExp(v.regix), message: v.message };
  });
  let isErrorCodesMatched = false;
  errorCodesRegix.forEach((v) => {
    if (e.toString().match(v.regix)) {
      setMessage(v.message);
      isErrorCodesMatched = true;
    }
  });
  if (!isErrorCodesMatched) {
    setMessage("認証失敗：ログインに失敗しました");
  }
};
